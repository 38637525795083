<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="div-footer">
    <div class="container">
      <div class="div row">
        <div class="col-md-6">
          <div class="research-publication">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f418ab8cba7867296c5b168b37211108f46d6711ef9766e5688bcb5a16dfb1a7?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
              alt="Research Publication Logo"
              class="logo"
            />
            <p class="description">
              دار سطور المعرفة متخصصة في تقديم خدمات في التخصصات العلمية بمختلف
              المجالات هادفة إلى تحسين جودة البحث العلمي في السعودية
            </p>
            <h1 class="title">منصة سطور لخدمات الأبحاث العلمية</h1>
            <nav class="nav-container">
              <a href="#contact" class="nav-item nav-item-contact"
                >تواصل معنا</a
              >
              <a href="#" class="nav-item nav-item-about">عن سطور</a>
              <a href="#" class="nav-item nav-item-published"> الملف الشخصي</a>
              <a href="#home" class="nav-item nav-item-home">الرئيسية</a>
            </nav>
          </div>
        </div>
        <div class="col-md-6">
          <div class="contact-section">
            <h2 class="contact-title">تواصل معنا</h2>
            <p class="contact-email">linesofknowledge6@gmail.com</p>
            <p style="direction: ltr" class="contact-phone">+00966566432362</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.div-footer {
  padding-top: 20px;
  padding-bottom: 40px;
  justify-content: flex-end;
  background-color: #0c569c;
  display: flex;
  flex-direction: column;
  color: #fff;
}
style > .div-footer {
  display: flex;
  max-width: 340px;
  flex-direction: column;
  color: #fff;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.div-2-footer {
  justify-content: flex-end;
  align-items: end;
  border-color: rgba(255, 255, 255, 1);
  border-style: dashed;
  border-bottom-width: 1px;
  width: 97%;
  padding: 0 0px 25px;
  font: 22px/90% Tajawal, sans-serif;
}
.div-3-footer {
  display: flex;
  margin-top: 18px;
  gap: 20px;
  font-size: 20px;
  text-align: right;
  line-height: 155%;
}
.div-4-foooter {
  align-self: start;
  display: flex;
  flex-direction: column;
  align-items: end;
  flex: 1;
}

.img-footer {
  aspect-ratio: 0.31;
  object-fit: auto;
  object-position: center;
  width: 6px;
}
.gap-70 {
  gap: 70px;
}
.links {
  border-bottom: 0.1px dotted #ddd;
  border-color: #ddd;
  border-style: dashed;
  border-width: 0;
  border-bottom-width: 0.1px;
  width: 97%;
  padding: 0 0px 25px;
  font: 22px/90% Tajawal, sans-serif;
  justify-content: flex-end;
  align-items: end;
  margin-bottom: 20px;
}
.div-footer li {
  padding-bottom: 10px;
}
.div-footer li a,
.div-footer li {
  padding-right: 5px;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
}
.div-footer .contact li {
  display: flex;
  gap: 5px;
}
.div-b-footer {
  justify-content: flex-end;
  align-items: center;
  background-color: #0c569c;
  display: flex;
  flex-direction: column;
  color: #fff;
  letter-spacing: -0.5px;
  padding: 50px 60px 24px;
}
@media (max-width: 991px) {
  .div-b-footer {
    padding: 0 20px;
  }
}
.div-2-b-footer {
  display: flex;
  margin-left: 109px;
  width: 892px;
  max-width: 100%;
  gap: 20px;
  justify-content: space-between;
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .div-2-b-footer {
    flex-wrap: wrap;
  }
}
.div-3-b-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media (max-width: 991px) {
  .div-3-b-footer {
    max-width: 100%;
  }
}
.div-4-b-footer {
  leading-trim: both;
  text-edge: cap;
  align-self: center;
  font: 700 17px/101% Tajawal, sans-serif;
}
.div-5-b-footer {
  leading-trim: both;
  text-edge: cap;
  margin-top: 22px;
  font: 500 15px/114% Tajawal, sans-serif;
}
@media (max-width: 991px) {
  .div-5-b-footer {
    max-width: 100%;
  }
}
.div-6-b-footer {
  display: flex;
  gap: 17px;
  font-size: 15px;
  font-weight: 500;
  line-height: 114%;
  margin: auto 0;
}
.div-7-b-footer {
  leading-trim: both;
  text-edge: cap;
  font-family: Tajawal, sans-serif;
  flex-grow: 1;
}
.div-8-b-footer {
  leading-trim: both;
  text-edge: cap;
  font-family: Tajawal, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
}
</style>
